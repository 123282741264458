import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not Found" />
    <Header />
    <Container className="mt-5">
      <Row className="mt-5">
        <Col>
          <h1 className="mt-5 mb-5">Are you lost?</h1>
          <p>We can&apos;t find the page you&apos;re looking for.</p>
          <p>
            Try our <Link to="/">homepage</Link> for up to date content.
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
